<template>
  <el-row class="warp">
    <el-col :span="24" class="warp-breadcrum">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }"><b>首页</b></el-breadcrumb-item>
        <el-breadcrumb-item>系统管理</el-breadcrumb-item>
        <el-breadcrumb-item>角色管理</el-breadcrumb-item>
      </el-breadcrumb>
    </el-col>

    <el-col :span="24" class="warp-main">
      <el-col :span="24" class="toolbar" style="padding-bottom: 0px;">
        <el-form :inline="true" :model="filters">
          <el-form-item>
            <el-input v-model="filters.roleName" placeholder="角色名称"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" v-on:click="getRoles(0)" icon="search">查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" v-on:click="goReset" icon="search">重置</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="showAddDialog" icon="plus" v-if="permissionButton.add">新增</el-button>
          </el-form-item>
        </el-form>
      </el-col>

      <el-table :data="roles" highlight-current-row v-loading="listLoading" @selection-change="selsChange" style="width: 100%;" border>
        <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
        <el-table-column prop="roleName" label="角色名称" width="120" align="center"></el-table-column>
        <el-table-column prop="description" label="描述" min-width="200"></el-table-column>
        <el-table-column prop="formatCreateTime" label="创建时间" width="180" align="center" sortable></el-table-column>
        <el-table-column label="操作" width="180" align="center">
          <template slot-scope="scope" v-if="scope.row.roleId!==1">
            <el-button size="small" @click="showEditDialog(scope.$index,scope.row)" v-if="permissionButton.edit">编辑</el-button>
            <el-button size="small" @click="roleViewDialog(scope.$index,scope.row)" v-if="permissionButton.preview">权限预览</el-button>
            <el-button type="danger" @click="delRole(scope.$index,scope.row)" size="small" :disabled="scope.row.isReadonly===1" v-show="permissionButton.del">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-col :span="24" class="table-footer">
        <el-pagination layout="prev, pager, next" @current-change="handleCurrentChange" :page-size="pageSize" :total="total" style="float:right;">
        </el-pagination>
      </el-col>

      <el-dialog title="新增" :visible.sync="addFormVisible" width="30%" :close-on-click-modal="false">
        <el-form :model="addForm" label-width="80px" :rules="addFormRules" ref="addForm">
          <el-form-item label="角色名称" prop="roleName">
            <el-input v-model="addForm.roleName" placeholder="请输入角色名称" maxlength="200"></el-input>
          </el-form-item>
          <el-form-item label="描述" prop="description">
            <el-input v-model="addForm.description" placeholder="请描述"></el-input>
          </el-form-item>
          <el-form-item label="权限" >
            <el-tree
              :data="add_tree_data"
              show-checkbox
              node-key="permissionId"
              :props="defaultProps" ref="addTree">
            </el-tree>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click.native="addFormVisible = false">取消</el-button>
          <el-button type="primary" @click.native="addSubmit" :loading="addLoading">提交</el-button>
        </div>
      </el-dialog>

      <el-dialog title="编辑" :visible.sync="editFormVisible" width="30%" :close-on-click-modal="false">
        <el-form :model="editForm" label-width="100px" :rules="editFormRules" ref="editForm">
          <el-form-item label="角色名" prop="roleName">
            <el-input v-model="editForm.roleName" placeholder="请输入角色名称" maxlength="30"></el-input>
          </el-form-item>
          <el-form-item label="描述" prop="description">
            <el-input v-model="editForm.description" placeholder="请描述" maxlength="50"></el-input>
          </el-form-item>
          <el-form-item label="权限" v-loading="edit_tree_loading">
            <el-tree
              show-checkbox
              node-key="permissionId"
              :data="edit_tree_data"
              :props="defaultProps" ref="editTree">
            </el-tree>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click.native="editFormVisible = false">取消</el-button>
          <el-button type="primary" @click.native="editSubmit" :loading="editLoading">提交</el-button>
        </div>
      </el-dialog>

      <el-dialog title="角色预览" :visible.sync="roleView" width="30%" :close-on-click-modal="false">
        <el-form label-width="100px" >
          <el-form-item label="权限" >
            <el-tree
              :data="view_tree_data"
              show-checkbox
              node-key="permissionId"
              :props="defaultProps" ref="viewTree">
            </el-tree>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click.native="roleView = false">取消</el-button>
        </div>
      </el-dialog>
    </el-col>
  </el-row>
</template>
<script>
  import {reqGetRoleListPage,reqDeleteRole,reqEditRole,reqAddRole,reqGetPermissionByRoleId,queryAdminPermissions} from '../../../api/system-api'
  import permission from '../../../common/permission';
  export default{
      data(){
          return{
              filters:{
                  roleName:''
              },
            roles:[],
            total:0,
            pageNum:1,
            pageSize:15,
            listLoading:false,
            sels:[],
            add_tree_data: [],
            edit_tree_data: [],
            view_tree_data: [],
            defaultProps: {
              children: 'children',
              label: 'permissionName'
            },
            count: 1,
            roleView: false,//编辑界面是否显示
            editFormVisible: false,//编辑界面是否显示
            editLoading: false,
            edit_tree_loading: true,
            permissionButton:new permission.PermissionButton,
            editFormRules: {
              roleName: [
                { required: true, message: '请输入角色名称', trigger: 'blur' },
                { min: 1, max: 30, message: '角色名称不能超过30个字符' },
              ],
              description: [
                { min: 1, max: 50, message: '角色描述不能超过50个字符', trigger: 'blur' },
              ],
            },
            editForm: {
              roleId: 0,
              roleName: '',
              description: '',
              delId:'',
            },
            addFormVisible: false,//新增界面是否显示
            addLoading: false,
            addFormRules: {
              roleName: [
                { required: true, message: '请输入角色名称', trigger: 'blur' },
                { min: 1, max: 30, message: '角色名称不能超过30个字符' },
              ],
              description: [
                { min: 1, max: 50, message: '角色描述不能超过50个字符', trigger: 'blur' },
              ],
            },
            addForm: {
              roleName: '',
              description: '',
              addId:'',
            }

          };
      },
    methods:{
      handleCurrentChange(val) {
        this.pageNum = val;
        this.getRoles();
      },
      getRoles(status) {
          let that=this;
        let para = {
          pageNum: status===0?0:this.pageNum,
          pageSize: this.pageSize,
          roleName: this.filters.roleName
        };
        that.listLoading = true;
        reqGetRoleListPage(para).then((res) => {
          that.total = res.data.data.total;
          that.roles = res.data.data.records;
          that.listLoading = false;
        })
      },
      selsChange: function (sels) {
        this.sels = sels;
      },
      goReset(){
          this.filters.roleName='';
          this.pageNum=1;
          this.getRoles();
      },
      delRole: function (index, row) {
          let that=this;
        that.$confirm('确认删除该记录吗?', '提示', {type: 'warning'}).then(() => {
          that.listLoading = true;
          let para = {roleId: row.roleId};
          reqDeleteRole(para).then((res) => {
            that.listLoading = false;
            if(res.data.code===1){
              that.$message({
                message: '删除成功',
                type: 'success'
              });
              that.getRoles();
            }else if(res.data.code===2){
              that.$message({
                message: res.data.msg,
                type: 'warning'
              });
            }else{
              that.$message({
                message: '删除失败',
                type: 'error'
              });
            }
          });
        }).catch(() => {
        });
      },
      roleViewDialog: function (index, row) {
        this.roleView = true;
        this.viewPermissionByRoleId(row.roleId);
      },
      showEditDialog: function (index, row) {
        this.editFormVisible = true;
        this.editForm = Object.assign({}, row);
        this.queryPermissionByRoleId(row.roleId);
      },
      editSubmit: function () {
          let that=this;
        that.$refs.editForm.validate((valid) => {
          if (valid) {
            that.$confirm('确认提交吗？', '提示', {}).then(() => {
              that.editLoading = true;
                delete that.editForm['createTime'];
              let para = Object.assign({}, that.editForm);
              var idArray=that.$refs.editTree.getCheckedKeys();
              para.permissionIds=idArray.join(',');
              reqEditRole(para).then((res) => {
                that.editLoading = false;
                if(res.data.code===1){
                  that.$message({
                    message: '提交成功',
                    type: 'success'
                  });
                  that.$refs['editForm'].resetFields();
                  that.editFormVisible = false;
                  that.getRoles();
                }else{
                  that.$message({
                    message: res.data.msg,
                    type: 'error'
                  });
                }
              }).catch(()=> {
                that.editLoading = false;
                that.$message({
                  message: "提交异常",
                  type: 'error'
                });
              })
            })
          }
        });
      },
      showAddDialog: function () {
        this.addFormVisible = true;
      },
      addSubmit: function () {
        let that=this;
        that.$refs.addForm.validate((valid) => {
          if (valid) {
            that.addLoading = true;
            let para = Object.assign({}, that.addForm);
            var idArray=that.$refs.addTree.getCheckedKeys();
            para.permissionIds=idArray.join(',');

            reqAddRole(para).then((res) => {
              if(res.data.code===1){
                that.addLoading = false;
                that.$message({
                  message: '提交成功',
                  type: 'success'
                });
                that.addLoading = false;
                that.$refs['addForm'].resetFields();
                that.addFormVisible = false;
                that.getRoles();
              }else {
                that.addLoading = false;
                that.$message({
                  message: res.data.msg,
                  type: 'error'
                });
              }
            }).catch(()=>{
              that.addLoading = false;
              that.$message({
                message: "提交异常",
                type: 'error'
              });
            })
          }
        });
      },
      removeItem:function(arry,val) {
        var index = this.indexOf(val);
        if (index > -1) {
          this.splice(index, 1);
        }
      },
      queryPermissionByRoleId(roleId) {
          let that=this;
        that.edit_tree_loading=true;
        reqGetPermissionByRoleId({roleId:roleId}).then((res) => {
          let checkedPermissions=res.data.data;
          let pushPermissionArray=[];
          if(checkedPermissions!==null&&checkedPermissions.length>0){
            checkedPermissions.forEach(function (obj) {
              pushPermissionArray.push(obj.permissionId+"");
            });
          }
          this.$refs.editTree.setCheckedKeys(pushPermissionArray);
          that.edit_tree_loading=false;
        })
      },
      viewPermissionByRoleId(roleId) {
          let that=this;
        that.edit_view_loading=true;
        reqGetPermissionByRoleId({roleId:roleId}).then((res) => {
          let checkedPermissions=res.data.data;
          let pushPermissionArray=[];
          if(checkedPermissions!==null&&checkedPermissions.length>0){
            checkedPermissions.forEach(function (obj) {
              pushPermissionArray.push(obj.permissionId+"");
            });
          }
          this.$refs.viewTree.setCheckedKeys(pushPermissionArray);
          that.view_tree_loading=false;
        })
      },
      getAllMenuList() {
          let that=this;
        queryAdminPermissions({}).then((res) => {
          that.add_tree_data=res.data.data;
          that.edit_tree_data=res.data.data;
          that.view_tree_data=res.data.data;
        })
      },
    },
    mounted(){
      var permissionId = this.$route.query.permissionId;
      permission.getPermissionButton(permissionId,this.permissionButton);
      this.getAllMenuList();
      this.getRoles();
    }
  }
</script>
